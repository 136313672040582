import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ACalendarComponent } from './a-calendar/a-calendar.component';


const routes: Routes = [
  { path: '',  component: ACalendarComponent},
  // { path: 'streamdimples',  component: VideoDimplesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
